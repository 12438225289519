/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&display=swap');

*{
  font-family: 'Poppins', sans-serif;
} */

.navbar-brand img{
  width: 120px!important;
  height: auto;
}

span.visually-hidden {
  display: none!important;
}
.carousel-indicators {
  display: none!important;
}

.principles p{
  font-weight: 600;
}

.tb_bottom_div{
  padding:10px;
  background-color: #FEE9AE;
  border-radius: 3px;
}
.tb_bottom{
  background-color: #FEE9AE;
}
p{
  text-align:justify!important;
  font-weight: 400!important;
  font-size: 17px!important;
}
.addtion_p p{
  line-height: 1!important;
}

h4{
  background-color: transparent!important;
  color:green!important;
  text-decoration: underline!important;
  font-weight: 700!important; 
}

.bg-warning{
  background-color: transparent!important;
}

.slider_text{
  padding: 10px!important;
  background-color: hsla(123, 94%, 21%, 0.567);
  border-radius: 5px;
}

.footer_image_des img{
  width: 250px!important;
  height: auto;
  border-radius: 8px;
  padding:10px!important;
}

.contact_page_logo img{
  width: 35%!important;
  height: auto;
}
.page_header_image img{
  width: 100%;
  height: auto;
}
















@media screen and (min-width: 992px){
  h4{
    font-size: 33px!important;
  }

  .home_slider img{
    width: 100%;
    height: 120vh!important;
  }
  .carousel-caption {
  position: absolute;
  right: 0!important;
  bottom: 20px;
  left: 50%!important;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  transform: translate(-50%, -20%)!important;
  top: 50%;
}
.home_slider h4{
  font-size: 35px!important;
  font-weight: 600;
}
.table{
  width: 95%!important;
}
.page_header_image img{
  width: 100%;
  height: 100vh;;
}


}

